import type { Den } from "../den"

// prettier-ignore
export const dens: Array<Den> = [
    {
        id: "-",
        sw: [],
        sh: [],
    },
    {
        id: "1676046420423019000",
        sw: [
            {
                species: 236,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 66,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 532,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 559,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 67,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 533,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 106,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 107,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 560,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 534,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 68,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 237,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 236,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 66,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 532,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 453,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 533,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 67,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 107,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 106,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 454,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 237,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 68,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 534,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676045320911390700",
        sw: [
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 517,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 677,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 574,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 605,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 281,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 678,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 1,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 575,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 518,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 576,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 338,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 517,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 677,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 577,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 605,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 281,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 678,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 578,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 518,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 579,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 337,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676044221399762700",
        sw: [
            {
                species: 438,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 524,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 111,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 525,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 689,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 112,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 185,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 526,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 213,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 438,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 524,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 111,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 525,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 689,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 112,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 185,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 526,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 213,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676051917981160000",
        sw: [
            {
                species: 10,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 736,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 290,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 595,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 11,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 632,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 737,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 291,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 12,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 596,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 738,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 632,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 10,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 736,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 290,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 595,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 632,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 11,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 737,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 291,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 12,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 596,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 738,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 632,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676050818469532000",
        sw: [
            {
                species: 10,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 742,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 824,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 595,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 11,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 825,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 596,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 12,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 743,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 10,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 742,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 824,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 595,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 11,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 825,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 596,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 12,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 743,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676049718957903600",
        sw: [
            {
                species: 92,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 355,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 425,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 708,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 592,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 710,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 93,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 356,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 426,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 709,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 711,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 593,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 92,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 355,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 425,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 708,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 592,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 710,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 93,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 356,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 426,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 709,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 711,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 593,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676048619446275300",
        sw: [
            {
                species: 129,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 458,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 223,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 170,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 320,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 224,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 226,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 321,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 746,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 129,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 458,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 223,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 170,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 320,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 224,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 226,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 321,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 746,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676056316027672800",
        sw: [
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 422,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 751,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 320,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 746,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 834,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 847,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 423,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 321,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 422,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 751,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 320,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 746,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 834,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 847,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 423,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 321,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676055216516044800",
        sw: [
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 194,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 535,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 341,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 90,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 536,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 834,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 195,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 537,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 194,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 535,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 341,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 90,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 536,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 834,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 195,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 537,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1675055760446190000",
        sw: [
            {
                species: 236,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 759,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 852,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 674,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 83,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 539,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 760,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 675,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 701,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 865,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 853,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 870,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 236,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 759,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 852,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 674,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 759,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 538,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 760,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 675,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 701,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 760,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 853,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 870,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1675056859957818400",
        sw: [
            {
                species: 599,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 52,
                altForm: 2,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 878,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 600,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 863,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 601,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 879,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 599,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 52,
                altForm: 2,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 878,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 600,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 863,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 601,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 879,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1675057959469446700",
        sw: [
            {
                species: 599,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 599,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 208,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 303,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 777,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 599,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 599,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 208,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 208,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 777,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1675059058981074700",
        sw: [
            {
                species: 439,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 824,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 177,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 856,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 825,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 857,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, true, false],
            },
            {
                species: 561,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 178,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 876,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 1,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 561,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 858,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 439,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 824,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 177,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 856,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 77,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 857,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, true, false],
            },
            {
                species: 561,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 178,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 876,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 765,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 78,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 858,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1675060158492703000",
        sw: [
            {
                species: 439,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 360,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 177,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 343,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, true, false],
            },
            {
                species: 561,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 178,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 876,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 1,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 344,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 202,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 439,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 360,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 177,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 343,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, true, false],
            },
            {
                species: 561,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 178,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 876,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 344,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 202,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1675061258004331300",
        sw: [
            {
                species: 837,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 524,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 525,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 689,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 95,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 526,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 464,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 837,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 524,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 525,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 689,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 526,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 95,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 464,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1675062357515959300",
        sw: [
            {
                species: 50,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 749,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 290,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 529,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 95,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 339,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 208,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 340,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 660,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 51,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 530,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 750,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 50,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 749,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 290,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 529,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 95,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 339,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 208,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 340,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 660,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 51,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 530,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 750,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1675063457027587600",
        sw: [
            {
                species: 843,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 562,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 449,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 220,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 328,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 221,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 329,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 618,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 867,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 450,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 330,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 844,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 843,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 562,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 449,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 328,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 220,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 221,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 618,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 329,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 867,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 330,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 450,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 844,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1675064556539216000",
        sw: [
            {
                species: 37,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 554,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 2,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 608,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 38,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 555,
                altForm: 2,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 58,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 608,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 59,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 59,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1675065656050844000",
        sw: [
            {
                species: 37,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 37,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 2,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 608,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 38,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 609,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 776,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 58,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 58,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 608,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 59,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 609,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 59,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1677890301423150300",
        sw: [
            {
                species: 37,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 554,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 2,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 38,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 555,
                altForm: 2,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 58,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 59,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 59,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1677889201911522300",
        sw: [
            {
                species: 582,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 220,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 459,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 712,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 583,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 221,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 713,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 460,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 584,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 582,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 220,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 459,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 712,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 583,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 221,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 713,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 460,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 584,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1677892500446407000",
        sw: [
            {
                species: 220,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 613,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 872,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 221,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 614,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 473,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 873,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 461,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 220,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 613,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 872,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 221,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 614,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 473,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 873,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 461,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1677891400934778600",
        sw: [
            {
                species: 361,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 872,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 554,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 459,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 460,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 362,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 873,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 478,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 555,
                altForm: 2,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 361,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 872,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 459,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 460,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 362,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 873,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 478,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 875,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1677894699469663200",
        sw: [
            {
                species: 172,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 309,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 595,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 170,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 737,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 310,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 596,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 738,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 26,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 172,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 309,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 595,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 170,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 737,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 310,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 596,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 738,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 26,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1677893599958035000",
        sw: [
            {
                species: 835,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 694,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 848,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 170,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 836,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 695,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 849,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 871,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 777,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 877,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 835,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 694,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 848,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 170,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 836,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 695,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 849,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 871,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 777,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 877,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1677896898492919600",
        sw: [
            {
                species: 406,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 273,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 761,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 43,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 274,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 44,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 762,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 275,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 763,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 45,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 182,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 406,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 270,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 761,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 43,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 271,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 44,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 762,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 272,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 763,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 45,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 182,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1677895798981291500",
        sw: [
            {
                species: 406,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 829,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 546,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 420,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 421,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 547,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 841,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 406,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 829,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 546,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 420,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 421,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 547,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 842,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1677881505330124800",
        sw: [
            {
                species: 710,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 708,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 710,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 755,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 710,
                altForm: 2,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 756,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 556,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 709,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 711,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 781,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 710,
                altForm: 3,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 710,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 708,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 710,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 755,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 710,
                altForm: 2,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 756,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 556,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 709,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 711,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 781,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 710,
                altForm: 3,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1677880405818496500",
        sw: [
            {
                species: 434,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 568,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 451,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 43,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 452,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 45,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 569,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 435,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 434,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 568,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 451,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 43,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 452,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 45,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 435,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 569,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676898541934693400",
        sw: [
            {
                species: 848,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 92,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 451,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 43,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 44,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 93,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 109,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 45,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 849,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 110,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 848,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 92,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 451,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 43,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 44,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 93,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 109,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 45,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 849,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 110,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676899641446321400",
        sw: [
            {
                species: 519,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 163,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 177,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 627,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 527,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 520,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 521,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 164,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 528,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 178,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 628,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 561,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 519,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 163,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 177,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 629,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 527,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 520,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 521,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 164,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 528,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 178,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 630,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 561,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676896342911436800",
        sw: [
            {
                species: 821,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 714,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 278,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 177,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 425,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 822,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 426,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 279,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 178,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 823,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 701,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 845,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 821,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 714,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 278,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 177,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 425,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 822,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 426,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 279,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 178,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 823,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 701,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 845,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676897442423065000",
        sw: [
            {
                species: 173,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 175,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 742,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 684,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 35,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 755,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 176,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 36,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 743,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 756,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 685,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 468,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 173,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 175,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 742,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 682,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 35,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 755,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 176,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 36,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 743,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 756,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 683,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 468,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676894143888180500",
        sw: [
            {
                species: 439,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 868,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 35,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 281,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 36,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 869,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 303,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 439,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 868,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 35,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 281,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 36,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 869,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 78,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676895243399808800",
        sw: [
            {
                species: 509,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 434,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 686,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 510,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 435,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 461,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 687,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 275,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 509,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 434,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 686,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 510,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 435,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 461,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 687,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 302,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676891944864924200",
        sw: [
            {
                species: 827,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 263,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 509,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 633,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 828,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 264,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 634,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 862,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 635,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 827,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 263,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 509,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 629,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 828,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 264,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 630,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 862,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 248,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676893044376552200",
        sw: [
            {
                species: 714,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 328,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 610,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 714,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 782,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 329,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 783,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 611,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 612,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 330,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 776,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 784,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 714,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 610,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 328,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 714,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 704,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 329,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 611,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 705,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 330,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 612,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 780,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 706,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676907338027719000",
        sw: [
            {
                species: 714,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 782,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 885,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 714,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 886,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 715,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 783,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 784,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 841,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 887,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 714,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 704,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 885,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 714,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 886,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 715,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 705,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 706,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 842,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 887,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1676908437539347200",
        sw: [
            {
                species: 659,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 163,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 519,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 572,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 694,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 759,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 660,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 164,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 521,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 695,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 573,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 760,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 659,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 163,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 519,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 572,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 694,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 759,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 660,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 164,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 521,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 695,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 573,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 760,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1679873820400064500",
        sw: [
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 831,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 263,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 446,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 876,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 1,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 820,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 264,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 820,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 832,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 660,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 628,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 143,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 831,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 263,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 446,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 876,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 2,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 820,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 264,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 820,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 832,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 660,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 765,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 143,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1679872720888436500",
        sw: [
            {
                species: 535,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 90,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 170,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 536,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 746,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 537,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 847,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 535,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 90,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 170,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 536,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 746,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 537,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 847,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1679871621376808200",
        sw: [
            {
                species: 422,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 98,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 341,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 689,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 423,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 593,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 834,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 422,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 98,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 341,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 689,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 423,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 593,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 834,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13438842885794419000",
        sw: [
            {
                species: 92,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 562,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 355,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 93,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 710,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, true, false],
            },
            {
                species: 356,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 867,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 855,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 711,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 477,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 94,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 92,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 562,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 355,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 93,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 222,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, true, false],
            },
            {
                species: 356,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 302,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 867,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 864,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 477,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 94,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13438843985306049000",
        sw: [
            {
                species: 129,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 349,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 350,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 129,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 349,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 350,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13438845084817676000",
        sw: [
            {
                species: 447,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 599,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 95,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 632,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 600,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 208,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 601,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 448,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 447,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 599,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 95,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 600,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 632,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 208,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 601,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 448,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13438837388236278000",
        sw: [
            {
                species: 767,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 824,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 588,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 751,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 616,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 825,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 768,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 589,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 292,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 767,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 824,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 616,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 751,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 588,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 825,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 768,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 617,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 292,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13438838487747908000",
        sw: [
            {
                species: 679,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 562,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 425,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 680,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 710,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, true, false],
            },
            {
                species: 426,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 711,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 855,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 711,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 867,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 681,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 679,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 562,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 425,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 680,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 222,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, true, false],
            },
            {
                species: 426,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 302,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 855,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 864,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 867,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 681,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13438839587259535000",
        sw: [
            {
                species: 447,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 66,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 559,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 759,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 760,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 870,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, true, false],
            },
            {
                species: 67,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 560,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 68,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 766,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 448,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 475,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 447,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 66,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 453,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 759,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 760,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 870,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, true, false],
            },
            {
                species: 67,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 454,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 68,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 701,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 448,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 475,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13438840686771163000",
        sw: [
            {
                species: 52,
                altForm: 2,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 679,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 863,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 618,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 879,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 884,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 52,
                altForm: 2,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 679,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 863,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 618,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 879,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 884,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13438832990189767000",
        sw: [
            {
                species: 686,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 527,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 856,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 857,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 281,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 528,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 858,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 687,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 686,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 527,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 856,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 857,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 281,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 528,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 858,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 687,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13438834089701394000",
        sw: [
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 438,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 837,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 185,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 689,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 95,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 208,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 874,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 438,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 837,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 524,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 246,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 247,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 95,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 208,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 248,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13439833545771248000",
        sw: [
            {
                species: 194,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 339,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 562,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 622,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 536,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 195,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 618,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 623,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 423,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 537,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 867,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 464,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 194,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 339,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 562,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 622,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 536,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 195,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 618,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 623,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 423,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 537,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 867,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 464,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13439832446259620000",
        sw: [
            {
                species: 37,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 4,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 5,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 38,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 38,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 6,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 58,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 4,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 5,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 59,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 6,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13439831346747992000",
        sw: [
            {
                species: 37,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 608,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 38,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 609,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 776,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 58,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 608,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 59,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 609,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13439830247236364000",
        sw: [
            {
                species: 582,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 554,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 712,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 361,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 713,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 362,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 584,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 555,
                altForm: 2,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 582,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 613,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 712,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 361,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 713,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 362,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 584,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 875,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13439829147724737000",
        sw: [
            {
                species: 835,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 848,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 595,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 170,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 836,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 849,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 871,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 596,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 777,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 877,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 835,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 848,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 595,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 170,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 836,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 849,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 871,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 596,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 777,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 877,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13439828048213107000",
        sw: [
            {
                species: 172,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 309,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 848,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 694,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 595,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 479,
                altForm: 5,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 479,
                altForm: 4,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 479,
                altForm: 3,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 479,
                altForm: 2,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 479,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 172,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 309,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 848,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 694,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 595,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 479,
                altForm: 5,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 479,
                altForm: 4,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 479,
                altForm: 3,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 479,
                altForm: 2,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 479,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13439826948701479000",
        sw: [
            {
                species: 406,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 273,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 829,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 274,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 275,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 407,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 841,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 406,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 270,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 829,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 271,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 272,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 407,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 842,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13439825849189851000",
        sw: [
            {
                species: 420,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 273,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 829,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 546,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 274,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 755,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 421,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 756,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 547,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 275,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 781,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 420,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 270,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 829,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 546,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 271,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 755,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 421,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 756,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 547,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 272,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 781,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13439824749678223000",
        sw: [
            {
                species: 434,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 568,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 451,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 109,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 848,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 569,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 452,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 849,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 435,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 110,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 434,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 568,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 451,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 109,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 848,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 569,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 452,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 849,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 435,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 110,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13439823650166596000",
        sw: [
            {
                species: 177,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 163,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 821,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 278,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 12,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 822,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 164,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 279,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 178,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 701,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 823,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 177,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 163,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 821,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 278,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 12,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 822,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 164,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 279,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 178,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 701,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 823,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13440790120887603000",
        sw: [
            {
                species: 175,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 755,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 176,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 756,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 303,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 468,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 778,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 175,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 755,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 176,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 756,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 78,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 468,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 778,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13440791220399230000",
        sw: [
            {
                species: 827,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 263,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 559,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 510,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 264,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 828,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 675,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 461,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 560,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 862,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 635,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 827,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 263,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 629,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 510,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 264,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 828,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 675,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 461,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 630,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 862,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 248,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13440787921864346000",
        sw: [
            {
                species: 328,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 610,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 782,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 885,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 611,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 783,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 776,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 784,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 886,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 612,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 887,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 610,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 328,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 704,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 885,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 329,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 705,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 780,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 706,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 886,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 330,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 887,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13440789021375975000",
        sw: [
            {
                species: 659,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 519,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 520,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 831,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 521,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 832,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 628,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 876,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 1,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 143,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 659,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 519,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 520,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 831,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 521,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 832,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 765,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 876,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 143,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13440794518934116000",
        sw: [
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13440795618445744000",
        sw: [
            {
                species: 458,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 341,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 226,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 134,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 458,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 341,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 226,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 134,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13440792319910859000",
        sw: [
            {
                species: 686,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 527,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 856,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 857,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 528,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 687,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 858,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 196,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 686,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 527,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 856,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 857,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 528,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 687,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 858,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 196,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13440793419422489000",
        sw: [
            {
                species: 827,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 263,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 686,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 510,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 264,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 828,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 675,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 687,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 862,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 197,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 827,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 263,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 686,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 510,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 264,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 828,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 675,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 687,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 862,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 197,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13440798916980630000",
        sw: [
            {
                species: 420,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 761,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 829,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 546,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 762,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 421,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 763,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 547,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 470,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 420,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 761,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 829,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 546,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 762,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 421,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 763,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 547,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 470,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13440800016492257000",
        sw: [
            {
                species: 37,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 37,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 608,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 59,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 38,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 609,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 136,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 58,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 58,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 608,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 38,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 59,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 609,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 136,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13441641142887650000",
        sw: [
            {
                species: 835,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 848,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 694,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 170,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 836,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 849,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 695,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 738,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 135,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 835,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 848,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 694,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 170,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 836,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 849,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 695,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 738,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 135,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13441640043376022000",
        sw: [
            {
                species: 582,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 872,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 712,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 361,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 583,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 713,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 873,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 584,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 478,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 471,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 582,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 872,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 712,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 361,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 583,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 713,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 873,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 584,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 478,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 471,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13441643341910905000",
        sw: [
            {
                species: 175,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 684,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 176,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 685,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 868,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 468,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 700,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 175,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 682,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 176,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 683,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 868,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 468,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 700,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13441642242399277000",
        sw: [
            {
                species: 129,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 751,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 194,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 339,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 98,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 746,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 340,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 195,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 129,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 751,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 194,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 339,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 98,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 746,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 340,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 195,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13441636744841136000",
        sw: [
            {
                species: 458,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 223,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 320,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 98,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 550,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 224,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 321,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 226,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 458,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 223,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 320,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 98,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 550,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 224,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 321,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 226,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4972150845118706000",
        sw: [
            {
                species: 37,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 4,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 5,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 38,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 38,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 6,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 58,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 4,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 5,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 59,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 6,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4972151944630334000",
        sw: [
            {
                species: 129,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 98,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 746,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 423,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 834,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 129,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 98,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 746,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 423,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 834,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4972153044141962000",
        sw: [
            {
                species: 406,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 273,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 829,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 274,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 275,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 407,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 841,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 406,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 270,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 829,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 271,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 272,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 407,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 842,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4972145347560565000",
        sw: [
            {
                species: 37,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 608,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 38,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 609,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 58,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 608,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 59,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 609,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4972146447072194000",
        sw: [
            {
                species: 447,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 66,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 759,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 83,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 760,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 67,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, true, false],
            },
            {
                species: 870,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 701,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 448,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 475,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 865,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 68,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 679,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 562,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 92,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 680,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 222,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, true, false],
            },
            {
                species: 93,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 302,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 855,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 864,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 867,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 94,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4972147546583821000",
        sw: [
            {
                species: 175,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 755,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 176,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 756,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 303,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 468,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 869,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 175,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 77,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 176,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 756,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 78,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 468,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 869,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4972148646095450000",
        sw: [
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 438,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 837,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 185,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 689,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 95,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 208,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 874,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 582,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 613,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 712,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 361,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 713,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 362,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 584,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 875,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4972140949514053000",
        sw: [
            {
                species: 447,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 599,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 95,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 632,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 600,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 208,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 601,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 884,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 447,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 599,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 95,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 600,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 632,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 208,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 601,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 884,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4972142049025680000",
        sw: [
            {
                species: 52,
                altForm: 2,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 679,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 863,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 618,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 884,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 879,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 52,
                altForm: 2,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 597,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 679,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 863,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 618,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 884,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 879,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4973141505095535000",
        sw: [
            {
                species: 434,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 568,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 451,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 109,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 848,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 452,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 849,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 435,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 110,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 569,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 434,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 568,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 451,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 109,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 848,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 452,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 849,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 435,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 110,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 569,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4973140405583907000",
        sw: [
            {
                species: 175,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 684,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 176,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 685,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 868,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 468,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 858,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 175,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 682,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 176,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 683,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 868,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 468,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 858,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4973139306072279000",
        sw: [
            {
                species: 827,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 263,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 559,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 510,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 264,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 828,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 675,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 560,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 635,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 827,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 263,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 629,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 510,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 264,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 828,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 675,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 630,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 248,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4973138206560650000",
        sw: [
            {
                species: 177,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 163,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 821,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 278,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 12,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 822,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 164,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 279,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 178,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 701,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 561,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 823,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 177,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 163,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 821,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 278,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 12,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 822,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 164,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 279,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 178,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 701,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 561,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 823,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4973137107049022000",
        sw: [
            {
                species: 767,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 824,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 588,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 751,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 616,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 825,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 768,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 589,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 12,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [true, true, true, true, true],
            },
        ],
        sh: [
            {
                species: 767,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 824,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 616,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 751,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 588,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 825,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 768,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 617,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 12,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [true, true, true, true, true],
            },
        ],
    },
    {
        id: "4973136007537394000",
        sw: [
            {
                species: 341,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 98,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, true, true, true, true],
            },
        ],
        sh: [
            {
                species: 341,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 98,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, true, true, true, true],
            },
        ],
    },
    {
        id: "4973134908025766000",
        sw: [
            {
                species: 767,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 824,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 588,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 751,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 616,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 825,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 768,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 589,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, true, true, true],
            },
        ],
        sh: [
            {
                species: 767,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 824,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 616,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 751,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 588,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 825,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 768,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 617,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, true, true, true],
            },
        ],
    },
    {
        id: "4973133808514137000",
        sw: [
            {
                species: 194,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 339,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 562,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 622,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 536,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 195,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 618,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 623,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 423,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 537,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 464,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 844,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, true, true],
            },
        ],
        sh: [
            {
                species: 194,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 339,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 562,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 622,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 536,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 195,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 618,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 623,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 423,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 537,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 464,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 844,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, true, true],
            },
        ],
    },
    {
        id: "7556351317940148000",
        sw: [
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, true],
            },
        ],
        sh: [
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, true],
            },
        ],
    },
    {
        id: "7556352417451777000",
        sw: [
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, true],
            },
        ],
        sh: [
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, true],
            },
        ],
    },
    {
        id: "7556353516963405000",
        sw: [
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, true],
            },
        ],
        sh: [
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, true],
            },
        ],
    },
    {
        id: "7556345820382007000",
        sw: [
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "2498979820391853600",
        sw: [
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "2447364886159769000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 174,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 506,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 427,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 39,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 39,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 507,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 428,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 428,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 40,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 206,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 508,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 174,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 506,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 427,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 39,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 39,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 507,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 428,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 428,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 40,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 206,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 508,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "15632276665898510000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 506,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 759,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 39,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 427,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 39,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 206,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 832,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 428,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 508,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 760,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 40,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 506,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 759,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 39,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 427,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 39,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 206,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 832,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 428,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 508,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 760,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 40,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "2447363786648141000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 293,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 108,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 241,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 294,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 294,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 108,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 241,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 626,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 128,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 295,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 463,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 293,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 108,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 241,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 294,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 294,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 108,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 241,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 626,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 128,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 295,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 463,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "15632275566386880000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 293,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 128,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 108,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 241,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 241,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 626,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 128,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 295,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 573,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 295,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 463,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 293,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 128,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 108,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 241,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 241,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 626,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 128,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 295,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 573,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 295,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 463,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "6984833918694526000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 27,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 551,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 104,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 27,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 552,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 28,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 844,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 28,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 105,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 553,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 115,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 27,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 551,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 104,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 27,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 552,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 28,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 844,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 28,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 105,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 553,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 115,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "14413583907274220000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 27,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 104,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 328,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 328,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 552,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 28,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 105,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 553,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 115,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 330,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 623,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 27,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 104,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 328,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 328,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 552,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 28,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 105,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 553,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 115,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 330,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 623,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "7956530560371257000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 702,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 81,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 403,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 877,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 702,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 404,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 702,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 82,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 871,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 405,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 462,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 702,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 81,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 403,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 877,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 702,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 404,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 702,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 82,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 871,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 405,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 462,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "2024757571205803800",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 403,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 172,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 871,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 404,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 26,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 836,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 702,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 310,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 405,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 462,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 403,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 172,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 25,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 871,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 404,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 26,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 836,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 702,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 310,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 405,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 462,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "11635283243122928000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 661,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 527,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 627,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 587,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 662,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 662,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 628,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 587,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 528,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 628,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 663,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 661,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 527,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 629,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 587,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 662,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 662,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 630,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 587,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 528,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 630,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 663,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "17629394089387610000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 163,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 519,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 627,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 520,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 528,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 164,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 521,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 663,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 587,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 628,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 663,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 163,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 519,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 629,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 520,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 528,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 164,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 521,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 663,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 587,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 630,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 663,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "7854659797556875000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 127,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 825,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 127,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 127,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 123,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 123,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 127,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 212,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 214,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 557,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 825,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 214,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 214,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 123,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 123,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 214,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 212,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "5999950843982639000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 123,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 127,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 213,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 544,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 123,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 545,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 617,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 589,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 127,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 212,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 123,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 214,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 213,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 544,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 123,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 545,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 617,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 589,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 214,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 212,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "2997411918588892000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 590,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 753,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 548,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 754,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 591,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 114,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 549,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 754,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 549,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 591,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 465,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 590,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 753,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 548,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 754,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 591,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 114,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 549,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 754,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 549,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 591,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 465,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "12562706121429926000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 114,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 753,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 590,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 754,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 556,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 549,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 754,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 591,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 465,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 549,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 460,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 114,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 753,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 590,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 754,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 556,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 549,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 754,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 591,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 465,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 549,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 460,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "6589539950519384000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 661,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 636,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 2,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 662,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 636,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 663,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 637,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 661,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 636,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 2,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 662,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 636,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 663,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 637,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "3561902408726248000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 636,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 636,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 663,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 609,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 637,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 38,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 6,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 636,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 607,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 636,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 757,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 324,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 758,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 663,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 609,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 637,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 59,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 6,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "8769170721942625000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 524,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 111,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 525,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 112,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 112,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 526,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 745,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 464,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 524,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 111,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 525,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 112,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 112,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 526,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 745,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 464,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "14477537978666912000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 438,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 111,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 112,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 213,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 745,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 185,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 526,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 464,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 438,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 111,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 112,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 213,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 745,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 185,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 526,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 558,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 464,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13305292637317526000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 102,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 63,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 64,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 281,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 64,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 103,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 103,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 65,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 121,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 102,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 63,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 280,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 64,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 281,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 765,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 103,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 103,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 65,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 121,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "16069264858016262000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 605,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 63,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 79,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 605,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 64,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 79,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 518,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 876,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 1,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 606,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 65,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 678,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 1,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 605,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 63,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 79,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 605,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 765,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 79,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 518,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 876,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 606,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 65,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 678,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "6672704941776910000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 543,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 451,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 72,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 72,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 544,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 544,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 452,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 452,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 545,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 543,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 451,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 72,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 72,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 544,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 544,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 452,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 452,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 545,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "17951961757311600000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 544,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 544,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 591,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 545,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 452,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 110,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 545,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 544,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 544,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 211,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 591,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 545,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 452,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 110,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 545,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "14284833672245135000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 318,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 318,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 570,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 319,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 687,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 452,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 687,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 319,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 571,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 318,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 318,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 570,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 319,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 687,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 452,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 687,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 319,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 571,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "7704513452465554000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 570,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 318,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 570,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 686,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 552,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 687,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 828,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 571,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 319,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 510,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 553,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 570,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 318,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 570,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 686,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 552,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 687,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 828,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 571,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 319,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 510,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 553,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13563999851587424000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 619,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 852,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 619,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 559,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 766,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 560,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 620,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 620,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 560,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 853,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 620,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 619,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 852,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 619,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 453,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 853,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 454,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 620,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 620,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 454,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 853,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 620,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "502513031628181000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 619,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 559,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 539,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 620,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 870,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 766,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 539,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 620,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 560,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 853,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 865,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 619,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 453,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 538,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 620,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 870,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 620,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 538,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 620,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 454,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 853,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 870,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "16341001078884807000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 174,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 298,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 764,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 39,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 183,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 764,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 707,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 184,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 40,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 764,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 174,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 298,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 764,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 39,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 183,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 764,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 707,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 184,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 40,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 764,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "9913932150092392000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 173,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 755,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 183,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 35,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 281,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 707,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 764,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 36,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 756,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 184,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 173,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 755,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 183,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 35,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 281,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 707,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 764,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 36,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 282,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 756,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 184,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "342604449375897800",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 769,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 592,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 104,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 425,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 593,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 426,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 770,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 593,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 426,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 105,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 770,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 769,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 592,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 104,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 425,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 593,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 426,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 770,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 593,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 426,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 105,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 770,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "8253110425161551000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 769,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 592,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 769,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 425,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 593,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 426,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 711,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 711,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 711,
                altForm: 2,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 711,
                altForm: 3,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 770,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 769,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 592,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 769,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 425,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 593,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 426,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 711,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 711,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 711,
                altForm: 2,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 711,
                altForm: 3,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 864,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "5830741396702654000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 707,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 81,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 81,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 227,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 82,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 82,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 707,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 462,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 227,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 707,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 81,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 624,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 81,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 227,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 82,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 82,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 707,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 462,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 227,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "17953607996949684000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 81,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 227,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 52,
                altForm: 2,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 82,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 601,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 227,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 863,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 448,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 81,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 227,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 52,
                altForm: 2,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 82,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 601,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 227,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 863,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 448,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 625,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "12738905581603037000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 782,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 116,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 117,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 621,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 783,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 621,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 841,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 230,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 784,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 704,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 116,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 117,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 621,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 705,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 621,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 842,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 230,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 706,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4426791916416849000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 116,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 621,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 776,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 782,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 117,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 783,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 776,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 621,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 715,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 230,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 784,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 116,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 621,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 780,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 704,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 117,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 705,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 780,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 621,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 715,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 230,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 706,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4780541378243794000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 60,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 194,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 118,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 61,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 61,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 119,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 195,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 62,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 186,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 60,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 194,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 118,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 61,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 61,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 119,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 195,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 62,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 186,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "18345017229883238000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 341,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 751,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 118,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 61,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 195,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 119,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 62,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 186,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 341,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 751,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 118,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 61,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 195,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 119,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 62,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 342,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 186,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4780540278732166000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 54,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 339,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 55,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 845,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 55,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 847,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 834,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 340,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 55,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 54,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 339,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 55,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 845,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 55,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 847,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 834,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 340,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 55,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "18345016130371610000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 535,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 54,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 536,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 55,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 340,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 55,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 847,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 537,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 550,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 846,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 535,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 54,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 536,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 55,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 340,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 55,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 847,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 537,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 550,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4780539179220538000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 72,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 98,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 72,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 223,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 746,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 224,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 226,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 72,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 98,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 72,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 223,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 746,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 224,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 226,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "18345015030859980000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 90,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 223,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 224,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 226,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 689,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 90,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 747,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 223,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 771,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 224,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 226,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 689,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 748,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4780546875801936000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 170,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 692,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 692,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 120,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 320,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 746,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 321,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 121,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 693,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 319,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 170,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 690,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 690,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 120,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 320,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 746,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 321,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 121,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 691,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 319,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "18345022727441380000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 692,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 120,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 320,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 279,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 117,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 693,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 121,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 693,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 319,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 690,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 120,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 320,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 279,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 117,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 691,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 171,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 121,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 691,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 319,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "15336172135299617000",
        sw: [
            {
                species: 440,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 440,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 440,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 440,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 440,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 440,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 440,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 440,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 440,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 440,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "11188576017968090000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "16882931869395425000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4515385547978136000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 415,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 416,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "5701088864462886000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 590,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 102,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 114,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 114,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 103,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 3,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 465,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 407,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 3,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 590,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 102,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 114,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 114,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 315,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 103,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 3,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 465,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 407,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 3,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "5701092162997770000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 129,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 72,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 120,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 121,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 689,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 9,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 129,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 72,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 120,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 121,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 689,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 9,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "5701091063486142000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 98,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 72,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 223,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 224,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 713,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 614,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 98,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 688,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 72,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 223,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 224,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 713,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 614,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "5701094362021027000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 54,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 339,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 55,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 845,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 55,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 847,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 550,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 340,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 834,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 833,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 54,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 339,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 55,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 845,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 55,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 847,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 550,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 340,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 834,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "5701093262509399000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 824,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 742,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 595,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 127,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 825,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 291,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 596,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 743,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 291,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 824,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 742,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 595,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 214,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 825,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 291,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 596,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 743,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 291,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 826,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "5701096561044283000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 843,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 529,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 843,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 529,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 28,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 552,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 844,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 553,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 530,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 553,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 844,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 843,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 529,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 843,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 529,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 28,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 552,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 844,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 553,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 530,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 553,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 844,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "5701095461532656000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 761,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 420,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 762,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 841,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 820,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 841,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 820,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 763,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 841,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 761,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 420,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 840,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 762,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 842,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 820,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 842,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 820,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 763,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 842,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4665094036540599000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "11519945754184083000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 132,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "15818376695778914000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 590,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 102,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 753,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 114,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 754,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 102,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 103,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 591,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 754,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 465,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 3,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 590,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 102,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 753,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 114,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 754,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 102,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 103,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 591,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 754,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 465,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 3,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "7725829814153603000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 129,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 72,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 120,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 90,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 121,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 9,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 129,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 72,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 120,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 90,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 73,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 121,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 91,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 9,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "6162140483756004000",
        sw: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 744,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 745,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 745,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 745,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 745,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 745,
                altForm: 2,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 113,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 744,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 745,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 745,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 745,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 745,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 745,
                altForm: 2,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "6162171270081594000",
        sw: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 744,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 745,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, false],
            },
            {
                species: 745,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, false],
            },
            {
                species: 745,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 745,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 745,
                altForm: 2,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
        ],
        sh: [
            {
                species: 242,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 744,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 744,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 745,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, false],
            },
            {
                species: 745,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, false],
            },
            {
                species: 745,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 745,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 745,
                altForm: 2,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
        ],
    },
    {
        id: "2756478418053350400",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 333,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 831,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 333,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 446,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 820,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 832,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 334,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 334,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 832,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 143,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 143,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 333,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 831,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 333,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 446,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 820,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 832,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 334,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 334,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 832,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 143,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 143,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4769195437400349000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 333,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 333,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 820,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 820,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 832,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 334,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 334,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 832,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 143,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 143,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 333,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 819,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 333,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 820,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 820,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 832,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 334,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 334,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 832,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 143,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 143,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4197853775535533600",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 240,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 240,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 608,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 126,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 609,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 126,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 467,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 240,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 850,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 240,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 608,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 126,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 609,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 126,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 467,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "7797506443826344000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 240,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 240,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 126,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 126,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 609,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 38,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 467,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 467,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 240,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 240,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 126,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 631,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 126,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 609,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 59,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 467,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 467,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 851,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1108881309583387400",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 138,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 349,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 138,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 349,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 340,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 550,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 139,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 550,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 350,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 369,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 140,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 349,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 140,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 349,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 340,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 550,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 141,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 550,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 350,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 369,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "2845993206239293000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 349,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 138,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 349,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 369,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 369,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 139,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 350,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 349,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 140,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 349,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 369,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 550,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 369,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 141,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 350,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 130,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 99,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "16299909383459598000",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 239,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 595,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 239,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 871,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 125,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 778,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 596,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 871,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 836,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 125,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 466,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 239,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 595,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 239,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 871,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 125,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 778,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 596,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 871,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 836,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 125,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 466,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4268295780237511000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 239,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 239,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 702,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 596,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 125,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 836,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 849,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 871,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 466,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 466,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 849,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 239,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 239,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 702,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 596,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 125,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 836,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 849,
                altForm: 1,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 871,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 466,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 466,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 849,
                altForm: 1,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "538718828553644350",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 829,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 347,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 347,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 345,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 347,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 348,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 346,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 829,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 345,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 345,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 347,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 345,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 346,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 598,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 348,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "10639252279486992000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 347,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 345,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 347,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 345,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 12,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 348,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 346,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 348,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 12,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 345,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 347,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 345,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 347,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 752,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 12,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 346,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 348,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 830,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 346,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 12,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "7520360650147353000",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 238,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 554,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 238,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 698,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 221,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 460,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 124,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 873,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 555,
                altForm: 2,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 699,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 362,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 238,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 220,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 238,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 698,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 221,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 460,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 124,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 873,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 873,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 699,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 362,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "3231560995259523000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 361,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 554,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 238,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 698,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 362,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 460,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 124,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 873,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 555,
                altForm: 2,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 699,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 473,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 361,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 361,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 238,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 698,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 362,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 460,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 124,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 873,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 873,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 699,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 473,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "7520357351612468000",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 613,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 363,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 363,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 712,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 364,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 615,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 584,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 614,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 365,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 713,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 875,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 363,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 363,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 875,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 364,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 615,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 584,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 614,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 365,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 713,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1345818289025325000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 363,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 364,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 364,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 713,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 615,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 365,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 584,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 365,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 363,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 364,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 364,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 713,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 615,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 365,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 584,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 365,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 131,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "8444690290455067000",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 532,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 622,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 622,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 533,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 623,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 534,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 623,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 623,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 534,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 532,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 622,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 622,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 533,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 623,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 534,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 623,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 623,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 534,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "221992188589330700",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 870,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 236,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 533,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 83,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 870,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 623,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 865,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 534,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 106,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 237,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 68,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 870,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 236,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 533,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 533,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 870,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 623,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 534,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 534,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 107,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 237,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 68,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "5955975221769392000",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 29,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 41,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 29,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 32,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 30,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 33,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 31,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 42,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 42,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 31,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 34,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 32,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 41,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 32,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 29,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 33,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 30,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 34,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 42,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 42,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 34,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 31,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "14450795946632080000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 41,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 568,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 30,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 79,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 42,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 569,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 31,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 31,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 34,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 169,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 569,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 41,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 568,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 33,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 79,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 42,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 569,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 34,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 34,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 31,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 169,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 569,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4805937820974168000",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 41,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 714,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 333,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 42,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 42,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 822,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 42,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 334,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 715,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 823,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 169,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 41,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 714,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 333,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 42,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 42,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 822,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 42,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 334,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 715,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 823,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 169,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "11331443048367530000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 41,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 527,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 822,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 42,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 528,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 823,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 142,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 334,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 169,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 715,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 823,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 41,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 527,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 822,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 42,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 528,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 823,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 142,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 334,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 169,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 715,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 823,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1716759284250366200",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 439,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 343,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 575,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 344,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 876,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 1,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 576,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 344,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 439,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 77,
                altForm: 1,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 436,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 578,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 344,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 876,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 579,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 78,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 437,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "12829170745926814000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 876,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 1,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 574,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 79,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 375,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 876,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 1,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 876,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 1,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 576,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 576,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 376,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 876,
                altForm: 1,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 577,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 122,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 79,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 375,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 876,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 876,
                altForm: 1,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 866,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 579,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 579,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 376,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "6189149299220963000",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 874,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 304,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 304,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 874,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 874,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 305,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 303,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 305,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 703,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 306,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 837,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 304,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 304,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 838,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 305,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 302,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 305,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 703,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 306,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "744948697234498200",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 303,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 304,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 305,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 305,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 213,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 303,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 303,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 306,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 306,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 302,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 304,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 305,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 305,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 213,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 302,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 302,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 306,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 306,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 839,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "5162770839310267000",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 885,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 708,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 778,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 361,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 886,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 778,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 303,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 362,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 478,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 709,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 778,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 885,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 708,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 778,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 361,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 886,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 778,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 302,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 362,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 478,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 709,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 778,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "11690997354028680000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 885,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 885,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 885,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 93,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 303,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 709,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 709,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 303,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 887,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 887,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 94,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 885,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 885,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 885,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 222,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 302,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 709,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 864,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 302,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 887,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 887,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 94,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "4408860220788168700",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 621,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 371,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 371,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 696,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 372,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 147,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 372,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 621,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 697,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 373,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 373,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 621,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 443,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 443,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 696,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 444,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 147,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 444,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 621,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 697,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 445,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 445,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "18001771904838230000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 884,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 371,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 696,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 776,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 372,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 884,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 149,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 697,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 373,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 373,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 884,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 884,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 443,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 696,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 780,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 444,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 884,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 149,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 697,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 445,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 445,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 884,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "11147942343095867000",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 264,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 359,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 862,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 359,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 461,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 860,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 264,
                altForm: 1,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 359,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 862,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 359,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 461,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "1812702195150859500",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 359,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 828,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 510,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 359,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 461,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 359,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 859,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 359,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 215,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 828,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 510,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 359,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 461,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 359,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 861,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "9125837977236589000",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 304,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 632,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 304,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 374,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 305,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 375,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 823,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 632,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 879,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 306,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 306,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 304,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 632,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 304,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 374,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 305,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 375,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 823,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 632,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 879,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 306,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 306,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "16150871691787878000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 304,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 52,
                altForm: 2,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 632,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 305,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 863,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 863,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 879,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 306,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 376,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 376,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 879,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 304,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 52,
                altForm: 2,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 632,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 305,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 863,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 863,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 879,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 306,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 376,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 376,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 879,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "14439216054291850000",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 173,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 703,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 856,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 173,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 857,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 35,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 703,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 703,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 36,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 547,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 858,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 173,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 703,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 856,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 173,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 857,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 35,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 703,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 703,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 36,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 547,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 858,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "8284890978883699000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 703,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 546,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 35,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 703,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 703,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 547,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 110,
                altForm: 1,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 858,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 36,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 110,
                altForm: 1,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 858,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 703,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 546,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 35,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 703,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 703,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 547,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 110,
                altForm: 1,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 858,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 36,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 110,
                altForm: 1,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 858,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "17302261471610569000",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 854,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 854,
                altForm: 1,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
        ],
    },
    {
        id: "10041392713565151000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 854,
                altForm: 1,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, false],
            },
            {
                species: 855,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 855,
                altForm: 1,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 869,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, false, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, false, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, false, false],
            },
            {
                species: 854,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, false],
            },
            {
                species: 869,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 854,
                altForm: 1,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, false],
            },
            {
                species: 855,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 855,
                altForm: 1,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 869,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: true,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "16685003352010291000",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 136,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 135,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 134,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 196,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 197,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 470,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 471,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 700,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 136,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 135,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 134,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 196,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 197,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 470,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 471,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 700,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
        ],
    },
    {
        id: "13686551123076485000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 136,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 135,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 134,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 196,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 197,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 470,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 471,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 700,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 133,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 2,
                isGmax: false,
                stars: [true, true, true, true, true],
            },
            {
                species: 136,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 135,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 134,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 196,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 197,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 470,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 471,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 700,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
        ],
    },
    {
        id: "6395957127820209000",
        sw: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 138,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 347,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 696,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 698,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 348,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 346,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 139,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 348,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 697,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 699,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 142,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
        sh: [
            {
                species: 531,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 140,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 345,
                altForm: 0,
                minFlawlessIVs: 1,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 698,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 696,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, true, true, false, false],
            },
            {
                species: 346,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 348,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 3,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 141,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 346,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 699,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 697,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 142,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 4,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
        ],
    },
    {
        id: "13032247726971474000",
        sw: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 138,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 139,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, false, false],
            },
            {
                species: 142,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, false, false],
            },
            {
                species: 881,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, false, false],
            },
            {
                species: 880,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 882,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, false],
            },
            {
                species: 142,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 880,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 882,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 881,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 883,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
        ],
        sh: [
            {
                species: 225,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, true],
            },
            {
                species: 140,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [true, true, false, false, false],
            },
            {
                species: 141,
                altForm: 0,
                minFlawlessIVs: 2,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, false, false],
            },
            {
                species: 142,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, false, false],
            },
            {
                species: 883,
                altForm: 0,
                minFlawlessIVs: 3,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, false, false],
            },
            {
                species: 882,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, true, true, false],
            },
            {
                species: 880,
                altForm: 0,
                minFlawlessIVs: 4,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, false],
            },
            {
                species: 142,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 882,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 880,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, false, true],
            },
            {
                species: 883,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
            {
                species: 881,
                altForm: 0,
                minFlawlessIVs: 5,
                abilityPool: 2,
                genderPool: 0,
                isGmax: false,
                stars: [false, false, false, true, true],
            },
        ],
    },
]
